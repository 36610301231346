<template>
    <div>
        
        <div v-if="!loadingPath && excelFilePath != null && excelFilePath.length > 0">
            <a-row :gutter="24">
                <a-col :span="24" class="text-center my-5 py-5">
                    <div id="excel-view"></div>
                </a-col>  
            </a-row>
            
        </div>

        <div v-if="!loadingPath && message.length > 0 && (excelFilePath == null || excelFilePath == 'null' || excelFilePath.length == 0)">

            <a-row type="flex" :gutter="24" justify="space-around" align="middle" class="" style="min-height: 60vh">

                <a-col :span="24" :md="12" :lg="12" class="text-center  justify-items-center">

                    <label>{{ message }}</label>

                </a-col>

            </a-row>
        </div>
        
        <div v-if="loadingPath">
            <a-row type="flex" :gutter="24" justify="space-around" align="middle" class="text-center" style="min-height: 80vh">

                <a-col :span="24" :md="12" :lg="12" class="text-center  justify-items-center">

                    <a-spin class="text-primary" size="large"/>

                </a-col>

            </a-row>
        </div>

    </div>
</template>
    
<script>
	import ExcelViewer from "excel-viewer";

    export default {
        data() {
			return {
				message: '',
                excelFilePath: null,
                loadingPath: false,
                loadedRatio: 0,
                firstRowNum: 2
                 
			}
				
		},
        created() {
            this.loadingPath = true;
            this.getFilePath();
        },
		computed: {
			proxyUrl() {
				return 'https://corsproxy.io/?';
			}
		},
        methods: {
            async getFilePath() {

                const excelFileUrl = await localStorage.getItem("excelFilePath");

                let container = this.$refs["excel-view"];

                new ExcelViewer('#excel-view', excelFileUrl);

                this.excelFilePath = excelFileUrl;

                this.loadingPath = false;


               
            },


        }
    }
    </script>
    <style scoped>
        

        
    </style>